@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































.sort {
  text-align: center;
  cursor: all-scroll;

  i {
    font-size: 18px;
  }
}

.btns span {
  color: #409eff;
  margin: 0 5px;
  cursor: pointer;
}

::v-deep {
  .el-dialog__body {
    padding: 10px 20px 20px;
  }
}

.el-select-dropdown {
  width: 500px !important;
}
