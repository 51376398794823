@import "~@/erp/styles/variables/variables.scss";














































































.wrapper-status {
  margin-top: 10px;
}
