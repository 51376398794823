@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.container {
  display: flex;
  .item {
    display: flex;
    line-height: 40px;
    .T {
      text-align: right;
    }
  }
  .L {
    width: 520px;
    .T {
      width: 130px;
    }
    .C {
      width: 390px;
      margin-left: 20px;
    }
  }
  .R {
    width: 640px;
    border-left: 1px solid #ededed;
    overflow-y: auto;
    padding-left: 20px;
    .T {
      width: 150px;
    }
    .C {
      width: 480px;
      margin-left: 20px;
      .view {
        color: #409eff;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
::v-deep {
  .el-input {
    width: 100%;
  }
  .el-form-item--small.el-form-item {
    margin: 4px 0;
  }
  .el-table {
    .el-table__header tr,
    .el-table__header th {
      padding: 0;
    }
  }
}
