@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.container {
  .title {
    > div {
      width: 50%;
      em {
        font-size: 14px;
        border: 2px solid #333;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        line-height: 18px;
        text-align: center;
        display: inline-block;
        margin-right: 5px;
      }
      span {
        display: inline-block;
        margin-right: 10px;
        line-height: 22px;
      }
      .event {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
  .main-wrap {
    margin-top: 10px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .anchor {
      position: fixed;
      top: 200px;
      right: 28px;
      border-radius: 4px;
      border: 1px solid #d1e9ff;
      z-index: 999;
      background: white;
      ul li {
        list-style: none;
        line-height: 24px;
        color: #409eff;
        border-top: 1px solid #d1e9ff;
        padding: 0 10px;
        cursor: pointer;
        margin-top: -1px;
      }
    }
    .vaild {
      width: 45%;
      padding-left: 10px;
      margin-top: 30px;
    }
    .main {
      padding: 0 10px;
      position: relative;
      margin-bottom: 50px;
      .l-main {
        width: 100%;
        padding-right: 55%;
        p {
          margin-bottom: 5px;
          text-indent: 20px;
        }
        .img-wrap {
          padding-left: 20px;
          margin-bottom: 10px;
          .img {
            width: 72px;
            height: 72px;
            position: relative;
            img {
              width: 72px;
              height: 72px;
              cursor: pointer;
            }
            span {
              font-size: 12px;
              position: absolute;
              top: 0;
              right: 0;
              background: #e8f4ff;
              border: 1px solid #d1e9ff;
              color: #409eff;
              line-height: 16px;
            }
            .img-icon {
              position: absolute;
              bottom: 0px;
              right: 0px;
              background: #000;
              opacity: 0.7;
              i {
                font-size: 16px;
                cursor: pointer;
                color: #fff;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
              }
            }
          }
          .text {
            padding-left: 10px;
            width: 100%;
            .more {
              color: #409eff;
              cursor: pointer;
              display: inline-block;
            }
            p {
              text-indent: 0;
            }
          }
        }
        .base-info {
          margin: 10px 0;
          .name {
            padding-right: 10px;
            text-align: right;
          }
          .res {
            width: calc(100% - 160px);
            .el-tag {
              cursor: pointer;
              margin-bottom: 5px;
            }
          }
        }
      }
      .r-main {
        top: 0;
        right: 0;
        width: 48%;
        height: 100%;
        padding: 0 20px 0 20px;
        position: absolute;
        .box {
          display: flex;
          margin-top: 20px;
          .title {
            width: 180px;
            background: #f6f8f9;
            margin-top: 1px;
          }
          .fees-tips {
            position: relative;
            display: flex;
            span {
              max-width: 140px;
            }
            i.del {
              font-size: 16px;
              position: absolute;
              top: 12px;
              left: -40px;
              cursor: pointer;
            }
          }
          .content {
            width: calc(100% - 190px);
            overflow-x: auto;
            display: flex;
            .price-box {
              width: 150px;
              margin: 1px -1px 0;
              .fees {
                .el-input {
                  width: 130px;
                  left: -10px;
                }
              }
              .additional-fees {
                .el-input {
                  width: 110px;
                  left: -10px;
                }
              }
              i.iconfont {
                top: -8px;
              }
            }
          }
          ul li {
            text-indent: 10px;
            list-style: none;
            line-height: 40px;
            border: 1px solid #eceff5;
            margin-top: -1px;
            margin-right: -1px;
          }
          .net-price,
          .additional-fees {
            position: relative;
            i {
              font-size: 18px;
              color: #409eff;
              position: absolute;
              top: 3px;
              right: 5px;
              cursor: pointer;
              z-index: 2;
            }
          }
        }
        .el-empty {
          padding: 20px 0;
          width: 100%;
          border: 1px solid #eceff5;
        }
      }
      .line {
        width: 1px;
        height: 100%;
        border-right: 1px solid #dedede;
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  .wrapper-mail {
    display: inline-block;
    margin-left: 10px;
  }
}
::v-deep {
  .el-input-group__prepend,
  .el-input-group__append {
    background: none;
    padding: 0;
  }
  .el-select {
    width: 100%;
  }
  .product-row {
    .el-form-item--small.el-form-item {
      margin-bottom: 5px;
    }
  }
  .product-row {
    .append-select {
      color: rgba(0, 0, 0, 0.65);
      height: 32px;
      border: none;
      background: #f5f7fa;
      cursor: pointer;
    }
  }
  .price-box {
    .append-select {
      color: rgba(0, 0, 0, 0.65);
      height: 32px;
      border: none;
      background: #f5f7fa;
      cursor: pointer;
      max-width: 60px;
    }
    .el-input {
      width: 140px;
    }
    .el-input-group__append {
      left: -10px;
    }
    .el-input__inner {
      padding: 0 0 0 10px;
    }
    .el-form-item--small.el-form-item {
      margin: 0;
    }
    .el-form-item__content {
      margin: 3px 0 3px 10px !important;
    }
  }
}
